<template>
  <div class="container">
    <div class="page-title">
      <div class="title">医生认证</div>
      <div class="tag">仅限公立医院正式医师（以下为必填）</div>
    </div>
    <van-form @submit="handleSubmit" label-width="78px" scroll-to-error :disabled="hadSubmit">
      <div class="box">
        <van-cell-group>
          <van-field
            v-model="state.name"
            label-class="label-bold"
            name="name"
            label="真实姓名"
            placeholder="请填写真实姓名"
            :rules="[
              {
                validator: validatorName,
                message: '请填写真实姓名',
                trigger: 'onChange',
              },
            ]"
          />
          <van-field
            v-model="state.region"
            is-link
            label-class="label-bold"
            readonly
            name="regionPicker"
            label="地区"
            placeholder="请选择医院所在地区"
            @click="show.showRegionPicker = true"
            :rules="[
              {
                validator: validatorBlank,
                message: '请选择医院所在地区',
                trigger: 'onBlur',
              },
            ]"
          />
          <van-popup v-model:show="show.showRegionPicker" position="bottom">
            <van-area
              :area-list="area"
              @confirm="handlePickerConfirm($event, 'area')"
              @cancel="show.showRegionPicker = false"
            />
          </van-popup>
          <van-field
            v-model="state.hospitalName"
            label-class="label-bold"
            name="hospitalPicker"
            label="医院"
            placeholder="请输入医院"
            :rules="[
              {
                validator: validatorBlank,
                message: '请输入医院',
                trigger: 'onBlur',
              },
            ]"
          >
            <template #button>
              <van-button
                size="mini"
                @click="show.showSearchDrawer = true"
                type="primary"
                >选择医院</van-button
              >
            </template>
          </van-field>
          <van-field
            is-link
            readonly
            @click="show.showDepartmentPicker = true"
            v-model="state.departmentName"
            name="departmentId"
            label="科室"
            label-class="label-bold"
            placeholder="请选择科室名称"
            :rules="[
              {
                validator: validatorBlank,
                message: '请选择科室名称',
                trigger: 'onBlur',
              },
            ]"
          />
          <van-popup v-model:show="show.showDepartmentPicker" position="bottom">
            <van-picker
              :columns="departmentList"
              @confirm="handlePickerConfirm($event, 'department')"
              :columns-field-names="{ text: 'title' }"
              @cancel="show.showDepartmentPicker = false"
            />
          </van-popup>
          <van-field
            v-model="state.title"
            name="title"
            label="职称"
            is-link
            readonly
            label-class="label-bold"
            placeholder="请选择职称"
            @click="show.showTitlePicker = true"
            :rules="[
              {
                validator: validatorName,
                message: '请选择职称',
                trigger: 'onBlur',
              },
            ]"
          />
          <van-popup v-model:show="show.showTitlePicker" position="bottom">
            <van-picker
              :columns="titleColums"
              @cancel="show.showTitlePicker = false"
              @confirm="handlePickerConfirm($event, 'title')"
            />
          </van-popup>
          <van-field
            is-link
            readonly
            name="calendar"
            label-class="label-bold"
            label="工作时间"
            v-model="state.startWorkTime"
            placeholder="请选择加入工作时间"
            @click="show.showCalendar = true"
            :rules="[
              {
                validator: validatorBlank,
                message: '请选择工作时间',
                trigger: 'onBlur',
              },
            ]"
          />
          <van-popup v-model:show="show.showCalendar" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择工作时间"
              :min-date="minDate"
              :formatter="dateformatter"
              v-model="currentDate"
              @confirm="handlePickerConfirm($event, 'startWorkTime')"
              @cancel="show.showCalendar = false"
            />
          </van-popup>
        </van-cell-group>
      </div>
      <div class="box">
        <div class="box-title">
          <div class="title label-bold">上传身份证件</div>
          <div class="tag">请上传身份证正反面照片</div>
        </div>
        <div class="box-upload box-upload-hidden">
          <div class="box-upload-container">
            <van-uploader
              v-model="idPhotoZList"
              image-fit="contain"
              :disabled="hadSubmit"
              class="uploader-img"
              :max-count="1"
            >
              <div class="phpto-box">
                <img
                  :style="{ display: idPhotoZList.length ? 'none' : 'block' }"
                  src="@/assets/idPhotoZ.png"
                /></div
            ></van-uploader>
          </div>
          <div class="box-upload-container">
            <van-uploader
              :max-count="1"
              class="uploader-img"
              :disabled="hadSubmit"
              v-model="idPhotoFList"
              image-fit="contain"
            >
              <div class="phpto-box">
                <img
                  :style="{ display: idPhotoFList.length ? 'none' : 'block' }"
                  src="@/assets/idPhotoF.png"
                />
              </div>
            </van-uploader>
          </div>
        </div>
        <van-field
          v-model="state.idNumber"
          name="title"
          label="身份证号码"
          label-class="label-bold"
          placeholder="请填写身份证号码"
          :rules="[
            {
              validator: validatorIdCard,
              message: '请填写正确身份证号码',
              trigger: 'onBlur',
            },
          ]"
        />
        <div class="box-title">
          <div class="title label-bold">上传工作胸牌</div>
          <div class="tag">请上传工作胸牌图片</div>
        </div>
        <div class="box-upload">
          <div class="box-upload-container">
            <van-uploader
              class="uploader-img img-many"
              :disabled="hadSubmit"
              v-model="gradeCertificatePhotoList"
              image-fit="contain"
              multiple
              max-count="4"
            >
              <div class="phpto-box">
                <img
                  :style="{
                    display:
                      gradeCertificatePhotoList.length >= 4 ? 'none' : 'block',
                  }"
                  src="@/assets/camara.png"
                />
              </div>
            </van-uploader>
          </div>
        </div>
        <!-- <van-field
          v-model="state.gradeCertificateNumber"
          name="gradeCertificateNumber"
          label="证书编号"
          label-class="label-bold"
          placeholder="请填写证书编号"
          :rules="[
            {
              validator: validatorBlank,
              message: '请填写证书编号',
              trigger: 'onBlur',
            },
          ]"
        />
        <van-field
          v-model="state.gradeCertificateIssueTime"
          name="gradeCertificateIssueTime"
          is-link
          readonly
          label-class="label-bold"
          label="签发日期"
          placeholder="请选择签发日期"
          @click="show.showGradeCertificateIssueTime = true"
          :rules="[
            {
              validator: validatorBlank,
              message: '请选择签发日期',
              trigger: 'onBlur',
            },
          ]"
        />
        <van-popup
          v-model:show="show.showGradeCertificateIssueTime"
          position="bottom"
        >
          <van-datetime-picker
            type="date"
            title="签发日期"
            :min-date="minDate"
            v-model="currentDate"
            :formatter="dateformatter"
            @confirm="handlePickerConfirm($event, 'gradeCertificateIssueTime')"
            @cancel="show.showGradeCertificateIssueTime = false"
          />
        </van-popup> -->
      </div>
      <div class="tip">
        <div>提示：</div>
        <div>
          以上信息仅用于身份核验，我们将为您严格保密，请放心填写。材料如有变动请及时更新，平台需要重新核验。
        </div>
      </div>
      <div class="button" v-show="!hadSubmit">
        <van-button
          class="button-submit"
          round
          block
          type="primary"
          native-type="submit"
          :loading="isLoading"
        >
          提交申请
        </van-button>
      </div>
    </van-form>
    <SearchDrawer
      v-model:show="show.showSearchDrawer"
      v-model:value="state.hospitalName"
      v-model:other="state.hospitalId"
      :list="hospitalList"
      :keyValue="{value:'hospitalName',other:'id'}"
      title="hospitalName"
      @searchClick="searchClick"
    ></SearchDrawer>
  </div>
</template>

<script>
import { doctorApply } from "@/api/member/member";
import { getHospitalList, getDepartmentList } from "@/api/hospital";
import { uploadFile } from "@/api/upload";
import { useStore } from "vuex";
import { isName, isBlank, isIdCard } from "@/utils/validate.js";
import { formatter, dateFormat } from "@/utils/formatter.js";
import {
  getStorage,
  setStorage,
  removeStorage,
} from "@/utils/doctorApplyStorage.js";
import { reactive, ref, onMounted, watch } from "vue";
import {
  Toast,
  Form,
  Field,
  CellGroup,
  Button,
  Popup,
  Picker,
  DatetimePicker,
  Uploader,
  Area,
} from "vant";
import SearchDrawer from "@/components/SearchDrawer";
import { areaList } from "@vant/area-data";
export default {
  name: "doctorApply",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader,
    [Area.name]: Area,
    SearchDrawer,
  },
  setup() {
    const store = useStore();
    const idPhotoFList = ref([]);
    const idPhotoZList = ref([]);
    const gradeCertificatePhotoList = ref([]);
    const departmentList = ref([]);
    const hospitalList = ref([]);
    const area = areaList;
    const titleColums = ["主任医师", "副主任医师", "主治医师", "住院医师"];
    const dateformatter = formatter;
    const isLoading = ref(false);
    const hadSubmit = ref(false);
    const currentDate = ref(new Date()); //默认日期选择器时间
    const show = reactive({
      showRegionPicker: false,
      showCalendar: false,
      showDepartmentPicker: false,
      showGradeCertificateIssueTime: false,
      showTitlePicker: false,
      showSearchDrawer: false,
    });
    let doStateDefault = {
      name: "",
      hospitalId: null,
      hospitalName: null,
      departmentId: null,
      departmentName: null,
      region: "",
      title: "",
      startWorkTime: null,
      idPhotoZ: "",
      idPhotoF: "",
      idNumber: null,
      gradeCertificatePhoto: "",
      gradeCertificateNumber: null,
      gradeCertificateIssueTime: null,
    };
    if (getStorage()) {
      let data = JSON.parse(getStorage());
      doStateDefault = { ...data };
  
    }
    const state = reactive(doStateDefault);
    var href = window.location.href;
    onMounted(async () => {
      await store.dispatch("user/setToken", href.split("?")[1].split("=")[1]); // 修改
      const { data: department } = await getDepartmentList();
      departmentList.value = department;
      const { data: getHospital } = await getHospitalList();
      hospitalList.value = [...getHospital];
    });
    const searchClick = async function (value) {
      const { data: getHospital } = await getHospitalList({ keyWord: value });
      hospitalList.value = [...getHospital];
    };
    // 监听state变化进行前端本地存储
    watch(state, (newProps) => {
      setStorage(JSON.stringify(newProps));
    });

    // 校验函数可以直接返回一段错误提示
    const validatorName = (val) => isName(val);
    const validatorBlank = (val) => !isBlank(val);
    const validatorIdCard = (val) => isIdCard(val);

    // 确认picker点击
    const handlePickerConfirm = (data, type) => {
      if (type == "department") {
        state.departmentId = data.id;
        state.departmentName = data.title;
        show.showDepartmentPicker = false;
      }
      if (type == "title") {
        state.title = data;
        show.showTitlePicker = false;
      }
      if (type == "area") {
        state.region = data[0].name + data[1].name + data[2].name;
        show.showRegionPicker = false;
      }
      if (type == "startWorkTime") {
        let date = new Date(data);
        state.startWorkTime = dateFormat("YYYY-mm-dd", date);
        show.showCalendar = false;
      }
      if (type == "gradeCertificateIssueTime") {
        let date = new Date(data);
        state.gradeCertificateIssueTime = dateFormat("YYYY-mm-dd", date);
        show.showGradeCertificateIssueTime = false;
      }
    };
    const handleSubmit = async () => {
      if (!idPhotoZList.value.length) {
        Toast("请上传身份证图片正面");
        return;
      }
      if (!idPhotoFList.value.length) {
        Toast("请上传身份证图片反面");
        return;
      }
      if (!gradeCertificatePhotoList.value.length) {
        Toast("请上传技术资格证书");
        return;
      }
      isLoading.value = true;
      let {
        data: { url: idPhotoZ },
      } = await uploadFile({
        file: idPhotoZList.value[0].file,
        upload: true,
      });
      state.idPhotoZ = idPhotoZ;
      let {
        data: { url: idPhotoF },
      } = await uploadFile({
        file: idPhotoFList.value[0].file,
        upload: true,
      });
      state.idPhotoF = idPhotoF;
      let imgArr = [];
      gradeCertificatePhotoList.value.map(async (v) => {
        let {
          data: { url: imgurl },
        } = await uploadFile({ file: v.file, upload: true });
        imgArr.push(imgurl);
        if (imgArr.length == gradeCertificatePhotoList.value.length) {
          state.gradeCertificatePhoto = imgArr.toString();
          handleUpload();
        }
      });
    };
    const handleUpload = async () => {
      let { code } = await doctorApply({ ...state });
      if (code == 200) {
        isLoading.value = false;
        hadSubmit.value = true;
        // 清空填写内容本地缓存
        removeStorage();
        Toast.success("上传成功，我们将在3个工作日内完成审核，请耐心等待");
      } else {
        isLoading.value = false;
      }
    };

    return {
      state,
      show,
      idPhotoFList,
      idPhotoZList,
      hospitalList,
      currentDate,
      area,
      titleColums,
      dateformatter,
      isLoading,
      hadSubmit,
      departmentList,
      gradeCertificatePhotoList,
      minDate: new Date(1949, 10, 1),
      validatorName,
      validatorBlank,
      validatorIdCard,
      handleSubmit,
      handlePickerConfirm,
      searchClick,
    };
  },
};
</script>
<style>
.label-bold {
  font-weight: bolder;
  font-size: 15px;
  color: #525659;
}
.button-submit {
  width: 100%;
}

.uploader-img .van-uploader__preview {
  background: #fff;
  width: 90px;
  height: 90px;
  border-radius: 10px;
  margin: 0;
  border: 1px dashed #a6aeb3;
  box-sizing: border-box;
}
.uploader-img .van-uploader__upload-icon {
  font-size: 40px;
}
.img-many .van-uploader__preview {
  margin-right: 10px;
  margin-bottom: 10px;
}
.uploader-img .van-image {
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss" scoped>
.container {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  .tip {
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
    color: #a6aeb3;
    font-size: 12px;
    margin-top: 30px;
    margin-bottom: 95px;
    line-height: 18px;
  }
  .button {
    position: fixed;
    bottom: 0px;
    margin: 10px 15px;
    left: 0;
    right: 0;
  }
  .button-submit {
    background: linear-gradient(90deg, #90aafe, #5e7dff);
    box-shadow: 0px 0px 10px 0px rgba(95, 126, 255, 0.5);
  }
}
.page-title {
  padding-left: 20px;
  padding-bottom: 25px;
  padding-top: 17px;
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #5e7dff;
  }
  .tag {
    color: #a6aeb3;
    font-size: 12px;
  }
}
.box {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  .box-title {
    padding: 10px 16px;
    .title {
      font-size: 15px;
    }
    .tag {
      color: #a6aeb3;
      font-size: 12px;
      margin-top: 6px;
    }
  }
  .box-upload-hidden {
    height: 90px;
    display: flex;
    .box-upload-container {
      height: 90px;
    }
  }
  .box-upload {
    padding: 10px 16px;
    .box-upload-container {
      margin-right: 10px;
      display: inline-block;
      img {
        width: 50px;
        height: 35px;
      }
      .phpto-box {
        width: 90px;
        height: 90px;
        border-radius: 10px;
        border: 1px dashed #a6aeb3;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>