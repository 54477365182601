<template>
  <van-popup
    :show="show"
    position="right"
    :style="{ height: '100%', width: '100%' }"
  >
    <van-search
      v-model="SearchValue"
      show-action
      :placeholder="placeholder"
      @search="onSearch"
      class="search-top"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="search-list">
      <van-cell-group style="padding-top: 55px"
        >
        <van-cell
          :title="item[title]"
          v-for="(item, index) in list"
          :key="index"
          @click="onSelect(item)"
        />
      </van-cell-group>
    </div>
  </van-popup>
</template>

<script>
import { ref } from "vue";
import { Popup, Search, Cell, CellGroup } from "vant";
export default {
  name: "SearchDrawer",
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String, //显示组件的title
      default: "",
      required: true,
    },
    keyValue: {
      type: Object, //显示组件的title
      default: function(){
          return {}
      },
    },
    placeholder: {
      type: String,
      default: "请输入关键词",
    },
  },
  components: {
    [Popup.name]: Popup,
    [Search.name]: Search,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
  },
  setup(props, { emit }) {
    const SearchValue = ref("");
    const onSearch = function () {
      emit("searchClick", SearchValue.value);
    };
    const onSelect = function (item) {
      Object.keys(props.keyValue).map(v=>{
          emit(`update:${v}`, item[props.keyValue[v]]);
      })
      emit("update:show", false);
    };
    return {
      SearchValue,
      onSearch,
      onSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.search-list {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
}
</style>>