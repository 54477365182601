export function formatter(type, val) {
    if (type === 'year') {
        return val + '年';
    }
    if (type === 'month') {
        return val + '月';
    }
    if (type === 'day') {
        return val + '日';
    }
    return val;
}

/**
 * 
 * @param {*} date
 * @description dateFormat("YYYY-mm-dd HH:MM", date) 
 * @returns 
 */
export function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}

