// import Vue from 'vue'
import axios from 'axios'
import {
    baseURL,
    contentType,
    messageName,
    requestTimeout,
    statusName,
    successCode,
    /* tokenName, */
} from '@/config'
import store from '@/store'
import qs from 'qs'
import router from '@/router'
import { isArray } from '@/utils/validate'
import { Toast } from 'vant'

// 操作正常Code数组
const codeVerificationArray = isArray(successCode)
    ? [...successCode]
    : [...[successCode]]


/**
* axios响应拦截器
* @param config 请求配置
* @param data response数据
* @param status HTTP status
* @param statusText HTTP status text
* @returns {Promise<*|*>}
*/
const handleData = async ({ data, status }) => {
    // 若data.code存在，覆盖默认code
    let code = data && data[statusName] ? data[statusName] : status
    // 若code属于操作正常code，则status修改为200
    if (codeVerificationArray.indexOf(data[statusName]) + 1) code = 200
    switch (code) {
        case 200:
            // 业务层级错误处理，以下是假定restful有一套统一输出格式(指不管成功与否都有相应的数据格式)情况下进行处理
            // 例如响应内容：
            // 错误内容：{ code: 1, msg: '非法参数' }
            // 正确内容：{ code: 200, data: {  }, msg: '操作正常' }
            // return data
            return data
        case 401:
            store
                .dispatch('user/resetAll')
                .then(() =>
                    router.push({ path: '/login', replace: true }).then(() => { })
                )
            break
        case 403:
            // router.push({ path: '/403' }).then(() => {})
            break
    }
    const errMsg = `${data[messageName]}`
    Toast.fail(errMsg);
    console.log(data)
    return data
}

/**
 * @description axios初始化
 */
const instance = axios.create({
    baseURL,
    timeout: requestTimeout,
    headers: {
        'Content-Type': contentType,
    },
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
    (config) => {
        const Authorization = store.getters['user/token']

        // 不规范写法 可根据setting.config.js tokenName配置随意自定义headers
        // if (token) config.headers[tokenName] = token

        // 规范写法 不可随意自定义
        if (Authorization) config.headers['Authorization'] = Authorization

        if (config.data && config.data.upload) {
            config.headers['Content-Type'] = 'multipart/form-data'
            config.transformRequest = [function (data) {
                var formData = new FormData();
                for (let it in data) {
                    formData.append(it, data[it]);
                }
                return formData
            }]
        } else {
            config.data = qs.stringify(config.data)
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
    (response) => handleData(response),
    (error) => {
        const { response } = error
        return handleData(response)
    }
)

export default instance