import request from '@/utils/request'


export function getHospitalList(params) {
    return request({
        url: '/hospital/list',
        method: 'get',
        params,
    })
}
export function getDepartmentList(params) {
    return request({
        url: '/hospital/getDepartmentList',
        method: 'get',
        params,
    })
}