import request from '@/utils/request'


/**
 * @description 
 * @param {name,hospitalId,departmentId,
 * region,title,startWorkTime,idPhotoZ,idPhotoF,
 * gradeCertificatePhoto,gradeCertificateNumber,
 * gradeCertificateIssueTime} params 
 * @returns 
 */
export function doctorApply(data) {
    return request({
        url: '/member/doctorApply',
        method: 'post',
        data,
    })
}