import cookie from 'js-cookie'
import { storage } from '@/config'
/**
 * @description 获取
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getStorage() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem('doctorApply')
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem('doctorApply')
    } else if ('cookie' === storage) {
      return cookie.get('doctorApply')
    } else {
      return localStorage.getItem('doctorApply')
    }
  } else {
    return localStorage.getItem('doctorApply')
  }
}

/**
 * @description 存储
 * @param data
 * @returns {void|*}
 */
export function setStorage(data) {
  if (localStorage) {
    if ('localStorage' === storage) {
      return localStorage.setItem('doctorApply', data)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem('doctorApply', data)
    } else if ('cookie' === storage) {
      return cookie.set('doctorApply', data)
    } else {
      return localStorage.setItem('doctorApply', data)
    }
  } else {
    return localStorage.setItem('doctorApply', data)
  }
}

/**
 * @description 移除
 * @returns {void|Promise<void>}
 */
export function removeStorage() {
  if (localStorage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem('doctorApply')
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else if ('cookie' === storage) {
      return cookie.remove('doctorApply')
    } else {
      return localStorage.removeItem('doctorApply')
    }
  } else {
    return localStorage.removeItem('doctorApply')
  }
}
